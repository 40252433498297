import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2023-05-04-schoolnik/body-landing"

const HarnettView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Advancing STI Diagnosis in Urgent Care: Point-of-Care Testing Solutions"
				videoDisplayTitle="Advancing STI Diagnosis in Urgent Care: Point-of-Care Testing Solutions"
				vimeoLink="https://player.vimeo.com/video/825226366?h=9d79f54bd8" 
				placeholderImage="2023-05-04-schoolnik-webinar-replay.jpg"
				localStorageID="2023-05-04-schoolnik-webinar-replay"
				pageSlug="/webinar-replay/2023-05-04-schoolnik/"
			/>
			<BodyLanding pageName="download" localStorageID="2023-05-04-schoolnik-webinar-replay" />
		</React.Fragment>
	)
}

export default HarnettView

export const Head = () => (
  <Seo
  title="Webinar: Advancing STI Diagnosis in Urgent Care: Point-of-Care Testing Solutions"
  description="Advancing STI Diagnosis in Urgent Care: Point-of-Care Testing Solutions"
  image="/meta/webinar-schoolnik-2023-05-04.jpg"
/>
)
